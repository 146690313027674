import Link from "next/link";
import { FC } from "react";

import Container from "@components/Container";
import GlobeIcon from "@components/GlobeIcon";

interface Props {
  hideLinks?: boolean;
}

const Footer: FC<Props> = ({ hideLinks = false }) => {
  return (
    <>
      <div className="Footer">
        <div className="links">
          {!hideLinks && (
            <Container>
              <div className="link-items">
                <div style={{ flex: 1 }}>
                  <GlobeIcon />
                  {` `}
                  <Link href="/">
                    <a>www.bobcatonlinetraining.com</a>
                  </Link>
                </div>
                <div style={{ flex: 0, margin: "auto 0" }}>
                  <ul className="link-list">
                    <li>
                      <Link href="/terms">
                        <a>Terms and Conditions</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/privacy">
                        <a>Privacy Policy</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Container>
          )}
        </div>
        <Container>
          <div className="sub-footer">
            <div className="sub-section-1">
              <img src="/images/doosan-corp-logo-black.jpeg" alt="Doosan Logo" />
            </div>
            <div className="sub-section-2">
              <div>
                <strong>Bobcat is a Doosan company.</strong> Doosan is a global leader in construction
                equipment, power and water solutions, engines, and engineering, proudly serving customers and
                communities for more than a century.
              </div>
              <div>
                Bobcat®, the Bobcat logo and the colors of the Bobcat machine are registered trademarks of
                Bobcat Company in the United States and various other countries.
              </div>
              <div>© 2023 Bobcat Company. All rights reserved.</div>
            </div>
          </div>
        </Container>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .Footer {
          }

          .Footer a {
            color: #898989;
            text-decoration: none;
          }

          .links {
            background-color: black;
            font-size: 0.9rem;
            min-height: 15px;
          }

          .link-list {
            padding: 0;
            margin: 0;
            list-style-type: none;
            align-items: center;
          }

          .link-list li {
            margin: 0;
            white-space: nowrap;
            height: 30px;
          }

          .sub-footer {
            margin: 20px 0;
            display: flex;
            line-height: 1.5;
            font-size: 11px;
          }

          .sub-section-1 {
            flex: 0;
            margin: 0 20px 0 0;
          }

          .sub-section-2 {
            font-family: Lato, Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
            flex: 1;
          }

          @media screen and (min-width: 1024px) {
            .link-items {
              display: flex;
            }

            .link-list {
              display: flex;
            }

            .link-list li {
              height: auto;
              margin: 0 0 0 20px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Footer;
